import React, { useState } from "react";
import Layout from "../../components/Layout/layout";
import SEO from "../../components/seo";
import ApplyForm from "../../page-components/apply-form";
import bg from "../../images/svg/vector-14.svg";
import ogImage from "../../images/global_assets/og-image.png";

const heroBg = "careers-hero.png";

const AffiliatePage = () => {
  return (
    <Layout
      transparentHeader
      isLanding
      useNewLPHeader
      className="non-fixed-width"
    >
      <SEO
        title="Careers & Job Culture at SpotOn Sales | SpotOn"
        image={`https://spoton.com/${ogImage}`}
        cannonical="https://www.spoton.com/careers/"
      />

      <img src={bg} alt="background" className="absolute top-0 right-0 " />

      <ApplyForm />
    </Layout>
  );
};

export default AffiliatePage;
