import React, { useState, useEffect } from "react";
import { isMobile } from "react-device-detect";
import { Form, Input, Select, Upload, Checkbox } from "antd";
import Heading from "../../components-v2/Base/HeadingBuilder";
import { subTitle, title } from "../../data/affiliates";
import CtaSecondary from "../../components-v2/Base/CtaSecondary";
import CtaPrimary from "../../components-v2/Base/CtaPrimary";
import { emailValidationRegExp, phoneValidationRegExp } from "../../constants";
import { encode } from "../../components/Forms/EnterpriseForm";

const { Option } = Select;

const netlifyFormName = "Job Application";

const labelStyles = "text-black-100 mb-1 font-[15px]";
const stepStyles = `
      relative flex flex-col
      gap-x-8
      gap-y-2
    `;
const errorStyles = "text-danger text-[12px] mt-1";

const ApplyForm = ({ step, setStep }) => {
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [country, setCountry] = useState("");
  const [zip, setZip] = useState("");
  const [phone, setPhone] = useState("");
  const [currentJobTitle, setCurrentJobTitle] = useState("");
  const [areasOfInterest, setAreasOfInterest] = useState("");
  const [file, setFile] = useState(null);

  const [termsAccepted, setTermsAccepted] = useState(false);

  const [showErrors, setShowErrors] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({
    fileSize: true,
    firstName: true,
    lastName: true,
    email: true,
    phone: true,
    country: true,
    zip: true,
    jobTitle: true,
    areasOfInterest: true,
  });

  const [submitted, setSubmitted] = useState(false);

  const onFileChange = (info) => {
    if (info.file) {
      setFile(info.file);
    }
  };

  const checkFileTypeSupportedError = () => {
    if (
      file.type !== "application/pdf" ||
      file.type !== "text/plain" ||
      file.type !==
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
      file.type !== "application/msword" ||
      file.type !== "text/rtf"
    ) {
      return true;
    }

    return false;
  };

  const checkFileErrors = () => {
    if (!file) {
      return true;
    }

    if (file.size / 1024 / 1024 > 2) {
      return true;
    }

    checkFileTypeSupportedError();

    return false;
  };

  const checkErrors = () => {
    setErrors({
      fileSize: checkFileErrors(),
      firstName: !firstName.trim().length > 0,
      lastName: !lastName.trim().length > 0,
      email: !emailValidationRegExp.test(email),
      phone: !phone.trim().length > 0,
      country: !country.trim().length > 0,
      zip: !zip.trim().length > 0,
      currentJobTitle: !currentJobTitle.trim().length > 0,
      areasOfInterest: !areasOfInterest.trim().length > 0,
      termsAccepted: !termsAccepted,
    });
  };

  useEffect(() => {
    checkErrors();
  }, [
    file,
    firstName,
    lastName,
    email,
    phone,
    country,
    zip,
    currentJobTitle,
    areasOfInterest,
    termsAccepted,
  ]);

  // const beforeUpload = () => {
  //   const isLt2M = file.size / 1024 / 1024 < 2;
  //
  //   if (!isLt2M) {
  //   }
  //
  //   return isLt2M;
  // };

  const onSubmit = async () => {
    setShowErrors(true);

    const formHasErrors = Object.values(errors).filter((val) => val).length;

    if (formHasErrors) {
      return null;
    }

    setLoading(true);

    await fetch(`/`, {
      method: `POST`,
      body: encode({
        "form-name": netlifyFormName,
        email,
        firstName,
        lastName,
        country,
        zip,
        phone,
        currentJobTitle,
        areasOfInterest,
        file,
      }),
    })
      .then(
        () => {
          setLoading(false);
          // navigate("/partners/affiliate/thank-you");
          setSubmitted(true);
        },
        (err) => {
          setLoading(false);
        }
      )
      .catch((err) => {
        setLoading(false);
      });
  };

  return (
    <>
      {/* Netlify form */}
      <form
        name={netlifyFormName}
        encType="multipart/form-data"
        data-netlify="true"
        hidden
      >
        <input type="text" name="email" />
        <input type="text" name="firstName" />
        <input type="text" name="lastName" />
        <input type="text" name="country" />
        <input type="text" name="zip" />
        <input type="text" name="phone" />
        <input type="text" name="currentJobTitle" />
        <input type="text" name="areasOfInterest" />
        <input type="file" name="file" />
      </form>

      <section
        className={`
      bg-black w-full mx-auto pt-40
      pb-64 md:pb-48 lg:pb-48 2xl:pb-80
      `}
      >
        <div className="max-w-1440 w-full mx-auto px-4 lg:px-10">
          <Heading
            level={1}
            injectionType={isMobile ? 2 : 1}
            withBlueSymbol
            className="text-white text-center relative"
          >
            Join Our Talent Network
          </Heading>
          <p className="relative mx-auto text-center text-black-500 text-p max-w-[630px]">
            Create your profile to receive alerts for latest career
            opportunities and SpotOn news. It’s a quick and easy way to connect
            with our recruiting team.
          </p>
        </div>
      </section>

      {submitted && (
        <section
          className={`
            -mt-64 md:-mt-48 lg:-mt-48 2xl:-mt-72
            mx-4 lg:mx-40 2xl:mx-64
            px-6 lg:px-10 2xl:px-24
            py-6 lg:py-10
            mb-32
            text-white
          `}
        >
          <Heading level={4} injectionType={4} className="text-white">
            Thanks for your submission!
          </Heading>
          <p className="text-center">
            Your info will be passed to our recruit team!
          </p>
        </section>
      )}

      {!submitted && (
        <section className="relative max-w-1440 w-full">
          <div
            className={`
            bg-white rounded-lg
            -mt-64 md:-mt-48 lg:-mt-48 2xl:-mt-72
            mx-4 lg:mx-40 2xl:mx-64
            px-6 lg:px-10 2xl:px-24
            py-6 lg:py-10
            mb-32
          `}
            style={{ boxShadow: "0px 32px 60px rgba(0, 0, 0, 0.07)" }}
          >
            <div className={stepStyles}>
              <div className="mb-8 mx-auto text-center">
                <p>
                  Choose your resume and upload to your profile. (.rtf, .doc,
                  .docx, .txt, .pdf files with a 2MB maximum file size are
                  supported)
                </p>
                <Upload
                  onChange={onFileChange}
                  beforeUpload={() => false}
                  accept=".rtf,.doc,.docx,.txt,.pdf"
                  maxCount={1}
                >
                  <div className="bg-primary text-white cursor-pointer px-4 py-2 rounded-lg">
                    Click to upload
                  </div>
                </Upload>
                <div
                  className={`${errorStyles} opacity-${
                    showErrors && errors.fileSize ? 100 : 0
                  }`}
                >
                  {/* eslint-disable-next-line no-nested-ternary */}
                  {!file
                    ? "Please attach a file"
                    : file.size / 1024 / 1024 > 2
                    ? "The file size can't exceed 2MB"
                    : checkFileTypeSupportedError()
                    ? "Unsupported file format"
                    : ""}
                </div>
              </div>

              <div>
                <div className={labelStyles}>Your Email</div>
                <Input
                  value={email}
                  onChange={({ target: { value } }) => setEmail(value)}
                  placeholder="E.g. johndoe@mail.com"
                  size="large"
                  style={{
                    borderColor: showErrors && errors.email ? "#F73E3C" : "",
                  }}
                />
                <div
                  className={`${errorStyles} opacity-${
                    showErrors && errors.email ? 100 : 0
                  }`}
                >
                  Please enter a valid email
                </div>
              </div>

              <div>
                <div className={labelStyles}>Your First Name</div>
                <Input
                  value={firstName}
                  onChange={({ target: { value } }) => setFirstName(value)}
                  placeholder="E.g. John"
                  size="large"
                  style={{
                    borderColor:
                      showErrors && errors.firstName ? "#F73E3C" : "",
                  }}
                />
                <div
                  className={`${errorStyles} opacity-${
                    showErrors && errors.firstName ? 100 : 0
                  }`}
                >
                  Please enter first name
                </div>
              </div>

              <div>
                <div className={labelStyles}>Your Last Name</div>
                <Input
                  value={lastName}
                  onChange={({ target: { value } }) => setLastName(value)}
                  placeholder="E.g. Doe"
                  size="large"
                  style={{
                    borderColor: showErrors && errors.lastName ? "#F73E3C" : "",
                  }}
                />
                <div
                  className={`${errorStyles} opacity-${
                    showErrors && errors.lastName ? 100 : 0
                  }`}
                >
                  Please enter last name
                </div>
              </div>

              <div>
                <div className={labelStyles}>Country</div>
                <Input
                  value={country}
                  onChange={({ target: { value } }) => setCountry(value)}
                  placeholder="E.g. USA"
                  size="large"
                  style={{
                    borderColor: showErrors && errors.country ? "#F73E3C" : "",
                  }}
                />
                <div
                  className={`${errorStyles} opacity-${
                    showErrors && errors.country ? 100 : 0
                  }`}
                >
                  Please enter your country
                </div>
              </div>

              <div>
                <div className={labelStyles}>Zip Code</div>
                <Input
                  value={zip}
                  onChange={({ target: { value } }) => setZip(value)}
                  placeholder="E.g. 12345"
                  size="large"
                  style={{
                    borderColor: showErrors && errors.zip ? "#F73E3C" : "",
                  }}
                />
                <div
                  className={`${errorStyles} opacity-${
                    showErrors && errors.zip ? 100 : 0
                  }`}
                >
                  Please enter your country
                </div>
              </div>

              <div>
                <div className={labelStyles}>Your Phone Number</div>
                <Input
                  value={phone}
                  onChange={({ target: { value } }) => setPhone(value)}
                  placeholder="E.g. 111 222 3333"
                  size="large"
                  style={{
                    borderColor: showErrors && errors.phone ? "#F73E3C" : "",
                  }}
                />
                <div
                  className={`${errorStyles} opacity-${
                    showErrors && errors.phone ? 100 : 0
                  }`}
                >
                  Please enter a valid phone
                </div>
              </div>

              <div>
                <div className={labelStyles}>Current Job Title</div>
                <Input
                  value={currentJobTitle}
                  onChange={({ target: { value } }) =>
                    setCurrentJobTitle(value)
                  }
                  placeholder="E.g. Sales representative"
                  size="large"
                  style={{
                    borderColor:
                      showErrors && errors.currentJobTitle ? "#F73E3C" : "",
                  }}
                />
                <div
                  className={`${errorStyles} opacity-${
                    showErrors && errors.currentJobTitle ? 100 : 0
                  }`}
                >
                  Please enter your current job title
                </div>
              </div>

              <div className="w-full">
                <div className={labelStyles}>Area of interest</div>
                <Form.Item
                  rules={[
                    {
                      required: true,
                      message: "Please select",
                    },
                  ]}
                  validateStatus={
                    showErrors && errors.areasOfInterest ? "error" : ""
                  }
                >
                  <Select
                    className="w-full"
                    defaultValue={areasOfInterest}
                    onChange={(value) => setAreasOfInterest(value)}
                    style={{
                      borderColor:
                        showErrors && errors.areasOfInterest ? "#F73E3C" : "",
                    }}
                  >
                    <Option value="" />
                    <Option value="corporate">Corporate</Option>

                    <Option value="sales">Sales</Option>

                    <Option value="product">Product</Option>
                  </Select>
                  <div
                    className={`${errorStyles} opacity-${
                      showErrors && errors.areasOfInterest ? 100 : 0
                    }`}
                  >
                    Please select area of interest
                  </div>
                </Form.Item>
              </div>

              <div className="flex gap-4">
                <Checkbox onChange={(e) => setTermsAccepted(e.target.checked)}>
                  <div>
                    Yes, I agree to the{" "}
                    <a
                      href="https://www.spoton.com/legal"
                      target="_blank"
                      className="text-primary hover:text-primary underline hover:underline"
                      rel="noreferrer"
                    >
                      Terms of Use
                    </a>{" "}
                    and{" "}
                    <a
                      href="https://www.spoton.com/legal/consumer-privacy"
                      target="_blank"
                      className="text-primary hover:text-primary underline hover:underline"
                      rel="noreferrer"
                    >
                      Privacy Policy*
                    </a>{" "}
                    I would like to be contacted with marketing and job alerts
                    from
                    <span className="text-primary font-bold"> SpotOn</span>. I
                    know I can unsubscribe at any time.
                  </div>
                </Checkbox>
              </div>
              <div>
                <div
                  className={`${errorStyles} opacity-${
                    showErrors && errors.termsAccepted ? 100 : 0
                  }`}
                >
                  Please agree to the terms of service
                </div>
              </div>

              <div className="mx-auto">
                <CtaPrimary
                  title="Submit"
                  onClick={onSubmit}
                  disabled={loading}
                />
              </div>
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default ApplyForm;
